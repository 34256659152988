// Completed.js
import React, { useState, useEffect } from 'react';
import './Completed.css';

const Completed = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Simulating a 2-second loading time

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="completed-overlay">
      {isLoading ? (
        <div className="completed-spinner"></div>
      ) : (
        <div className="completed-content">
          <div className="completed-checkmark">
            <div className="checkmark-circle">
              <img className="checkmark-img" src="check.png" alt='check'></img>
            </div>
          </div>
          <h2 className='completed-title'>Order Recieved!</h2>
          <p className='completed-subtitle'>We'll reach out to you in the next 24 hours with further instructions.</p>
          <button className="completed-close-button" onClick={onClose}>Close</button>
        </div>
      )}
    </div>
  );
};

export default Completed;