// Checkout.js
import React, { useState } from 'react';
import './Checkout.css';
import Completed from './Completed';
import axios from 'axios';

const API_ENDPOINT = 'https://z6kn7jy0y7.execute-api.us-east-1.amazonaws.com/dev';
const API_KEY = '8VCpzMIsnJ5xupk80US7f11sKZMEiigR1J3nbbQQ';

const Checkout = ({ isBuying, selectedConsole, selectedAmount, calculateCost }) => {
  const [paymentMethod, setPaymentMethod] = useState('paypal');
  const [paymentEmail, setPaymentEmail] = useState('');
  const [contactMethod, setContactMethod] = useState('email');
  const [contactInfo, setContactInfo] = useState('');
  const [showCompleted, setShowCompleted] = useState(false);
  const [error, setError] = useState('');

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
    setPaymentEmail('');
  };

  const handleContactMethodChange = (method) => {
    setContactMethod(method);
    setContactInfo('');
  };

  const handleContactInfoChange = (e) => {
    let value = e.target.value;
    if (contactMethod === 'phone') {
      value = value.replace(/\D/g, '');
      if (value.length > 3 && value.length <= 6) {
        value = `(${value.slice(0, 3)}) ${value.slice(3)}`;
      } else if (value.length > 6) {
        value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
      }
    }
    setContactInfo(value);
  };

  const handlePaymentEmailChange = (e) => {
    let value = e.target.value;
    if (paymentMethod === 'venmo') {
      value = value.replace(/^@/, '');  // Remove existing '@' if present
      value = '@' + value;  // Add '@' to the start
    }
    setPaymentEmail(value);
  };

  const validateInput = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\(\d{3}\)\s\d{3}-\d{4}$/;

    if (contactMethod === 'email' && !emailRegex.test(contactInfo)) {
      return false;
    }
    if (contactMethod === 'phone' && !phoneRegex.test(contactInfo)) {
      return false;
    }
    if (paymentMethod === 'paypal' && !emailRegex.test(paymentEmail)) {
      return false;
    }
    if (paymentMethod === 'venmo' && paymentEmail.trim() === '@') {
      return false;
    }
    return true;
  };

  const handleBuyOrSellClick = async () => {
    if (validateInput()) {
      try {
        setError('');
        console.log(API_KEY)
        const response = await axios.post(`${API_ENDPOINT}/submit_order`, {
          customerInfo: {
            contactMethod: contactMethod,
            contactInfo: contactInfo
          },
          paymentInfo: {
            method: paymentMethod,
            email: paymentEmail
          },
          orderDetails: {
            console: selectedConsole,
            amount: selectedAmount,
            isBuying: isBuying,
            cost: calculateCost()
          }
        }, {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': API_KEY
          }
        });

        if (response.status === 201) {
          setShowCompleted(true);
        } else {
          throw new Error('Unexpected response status');
        }
      } catch (error) {
        console.error('Error submitting order:', error);
        setError('Failed to submit order. Please try again.');
      }
    }
  };

  const isButtonDisabled = selectedAmount === 0 || !validateInput();

  return (
    <div className="checkout">
      <div className='order-info-container'>
        <div className="checkout-section-title">Your Order</div>
        <div className="checkout-cart-cost">
          ${calculateCost()}
        </div>
        <div className="checkout-cart-details">
          <p><strong>Console:</strong> {selectedConsole}</p>
          <p><strong>Amount:</strong> {selectedAmount.toLocaleString()} coins</p>
        </div>
      </div>
      <div className='payment-method-container'>
        <div className="checkout-section-title">{isBuying ? 'Payment Method' : 'Payout Method'}</div>
        <div className="payment-options">
        <label className="payment-option paypal">
            <div className="payment-option-content">
              <input 
                type="radio" 
                id="paypal" 
                name="paymentMethod" 
                checked={paymentMethod === 'paypal'}
                onChange={() => handlePaymentMethodChange('paypal')}
              />
              <img src="/paypal.png" alt="PayPal" className="payment-logo" />
            </div>
          </label>
          <label className="payment-option venmo">
            <div className="payment-option-content">
              <input 
                type="radio" 
                id="venmo" 
                name="paymentMethod" 
                checked={paymentMethod === 'venmo'}
                onChange={() => handlePaymentMethodChange('venmo')}
              />
              <img src="/venmo.png" alt="Venmo" className="payment-logo" />
            </div>
          </label>
        </div>
        <input 
          type="text" 
          placeholder={paymentMethod === 'paypal' ? "Enter your PayPal email" : "Enter your Venmo username"}
          value={paymentEmail}
          onChange={handlePaymentEmailChange}
          required
          className="payment-input"
        />
      </div>
      <div className="contact-method-container">
        <div className="checkout-section-title">Contact Method</div>
        <div className="contact-input-group">
          <div className="contact-method-options">
            <img 
              src="/email.png" 
              alt="Email" 
              className={`contact-method-icon ${contactMethod === 'email' ? 'selected' : ''}`}
              onClick={() => handleContactMethodChange('email')}
            />
            <img 
              src="/sms.png" 
              alt="Phone" 
              className={`contact-method-icon ${contactMethod === 'phone' ? 'selected' : ''}`}
              onClick={() => handleContactMethodChange('phone')}
            />
          </div>
          <input 
            type={contactMethod === 'email' ? "email" : "tel"}
            placeholder={contactMethod === 'email' ? "example@gmail.com" : "(___) ___-____"}
            value={contactInfo}
            onChange={handleContactInfoChange}
            required
            className="contact-input"
          />
        </div>
      </div>
      {error && <div className="error-message">{error}</div>}
      <button 
        className={`checkout-buy-button ${isButtonDisabled ? 'disabled' : ''}`}
        disabled={isButtonDisabled}
        onClick={handleBuyOrSellClick}
      >
        {isBuying ? 'Buy Now' : 'Sell Now'}
      </button>
      <div className="checkout-features">
        <div className="checkout-feature-item">
          <img src="/Extra/fast.png" alt="Fast Delivery" />
          <span>Fast Delivery</span>
        </div>
        <div className="checkout-feature-item">
          <img src="/Extra/guarantee.png" alt="Safe Process" />
          <span>Safe Process</span>
        </div>
        <div className="checkout-feature-item">
          <img src="/Extra/support.png" alt="24/7 Email Support" />
          <span>24/7 Email Support</span>
        </div>
      </div>
      {showCompleted && <Completed onClose={() => setShowCompleted(false)} />}
    </div>
  );
};

export default Checkout;