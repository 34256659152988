// src/Legal/TermsAndConditions.js
import React from 'react';
import './Legal.css';

const TermsAndConditions = () => {
  return (
    <div className="legal-container">
      <h1>Terms and Conditions</h1>
      <p>Last updated: July 11, 2024</p>
      
      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>By accessing or using RPOcoins, you agree to be bound by these Terms and Conditions. If you disagree with any part of the terms, you may not access the service.</p>
      </section>
      
      <section>
        <h2>2. Use of Service</h2>
        <p>Our service is intended for personal, non-commercial use. You must not use our service for any illegal or unauthorized purpose.</p>
      </section>
      
      <section>
        <h2>3. User Accounts</h2>
        <p>If you create an account with us, you are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account.</p>
      </section>
      
      <section>
        <h2>4. Content</h2>
        <p>Our service allows you to post, link, store, share and otherwise make available certain information, text, graphics, or other material. You are responsible for the content that you post to the service.</p>
      </section>
      
      <section>
        <h2>5. Intellectual Property</h2>
        <p>The service and its original content, features, and functionality are and will remain the exclusive property of RPOcoins and its licensors.</p>
      </section>
      
      <section>
        <h2>6. Termination</h2>
        <p>We may terminate or suspend your account and bar access to the service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation.</p>
      </section>
      
      <section>
        <h2>7. Limitation of Liability</h2>
        <p>In no event shall RPOcoins, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses.</p>
      </section>
      
      <section>
        <h2>8. Changes to Terms</h2>
        <p>We reserve the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect.</p>
      </section>
      
      <section>
        <h2>9. Contact Us</h2>
        <p>If you have any questions about these Terms, please contact us at rpocoins@gmail.com.</p>
      </section>
    </div>
  );
};

export default TermsAndConditions;