// Information.js
import React from 'react';
import './Information.css';

const Information = ({ isBuying }) => {
  return (
    <div className="coins-info">
      <h2 className="section-title">{isBuying ? 'Buying Coins on RPOcoins' : 'Selling Coins on RPOcoins'}</h2>
      {isBuying ? (
        <div>
          <p className="info-text">
            EA has implemented price ranges into the auction house, but don't worry - you can still purchase coins using our website. Here's how:
          </p>
          <ol>
            <li>Fill out the form with the required information</li>
            <li>Our team will reach out to you with specific instructions</li>
            <li>We'll request funds for your purchase</li>
            <li>Once payment is received, we'll purchase your card(s) from the auction house</li>
          </ol>
          <p className="info-text">
            After submitting this form, our team will contact you within 24 hours with detailed, personalized instructions to complete your purchase. All transactions are processed manually, and specific steps may vary for each individual order to ensure maximum safety and efficiency.
          </p>
        </div>
      ) : (
        <div>
          <p className="info-text">
            EA has implemented price ranges into the auction house, but don't worry - you can still sell coins using our website. Here's how:
          </p>
          <ol>
            <li>Fill out the form with the required information</li>
            <li>Our team will reach out to you with specific instructions</li>
            <li>Once you've purchased our card(s) from the auction house, we'll process your payout</li>
            <li>We'll send the money to the PayPal or Venmo account you provided</li>
          </ol>
          <p className="info-text">
            After submitting the form, our team will contact you within 24 hours with detailed, personalized instructions to complete your sale. Keep in mind that all transactions are handled manually, and specific steps may be tailored to each individual order for optimal security and efficiency.
          </p>
        </div>
      )}
      
      <h3 className="section-title">About RPOcoins</h3>
      <p className="info-text">
        It's tough in the NCAA Ultimate Team streets to make coins without giving your money directly to EA or spending too much time grinding the game. With RPOcoins, you don't have to worry. We've got you covered with the <strong>CHEAPEST prices</strong> and <strong>FASTEST delivery</strong> in the business.
      </p>
      
      <div className="disclaimer">
        <h4 className="disclaimer-title">Full Transparency of Risks</h4>
        <p>
          RPOcoins is a community-first site. We aim to provide full transparency regarding auction house risks while purchasing or selling coins. Our methods are community-based rather than botting methods commonly used by some coin sites. While bans are rare, we want to make everyone aware of the risks before proceeding. Our manual processing approach allows us to adapt to each transaction, further minimizing risks.
        </p>
      </div>
    </div>
  );
};

export default Information;