import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import './Admin.css';

const API_ENDPOINT = 'https://z6kn7jy0y7.execute-api.us-east-1.amazonaws.com/dev';
const API_KEY = '8VCpzMIsnJ5xupk80US7f11sKZMEiigR1J3nbbQQ';

const Admin = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem('isAdminAuthenticated') === 'true';
  });
  const [apiKey, setApiKey] = useState(() => {
    return localStorage.getItem('adminApiKey') || '';
  });
  const [password, setPassword] = useState('');
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
        const response = await axios.post(`${API_ENDPOINT}/verify_admin`, { password }, {
            headers: { 'x-api-key': API_KEY }
          });
      if (response.data.authenticated && response.data.apiKey) {
        setIsAuthenticated(true);
        setApiKey(response.data.apiKey);
        localStorage.setItem('isAdminAuthenticated', 'true');
        localStorage.setItem('adminApiKey', response.data.apiKey);
        setError('');
      } else {
        setError('Invalid password or authentication failed');
      }
    } catch (error) {
      console.error('Error during authentication:', error);
      setError('Authentication failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setApiKey('');
    localStorage.removeItem('isAdminAuthenticated');
    localStorage.removeItem('adminApiKey');
    setOrders([]);
  };

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_ENDPOINT}/get_orders`, {
        headers: { 'x-api-key': apiKey }
      });
      const fetchedOrders = response.data;
      if (Array.isArray(fetchedOrders)) {
        setOrders(fetchedOrders);
      } else {
        console.error('Fetched data is not an array:', fetchedOrders);
        setError('Invalid data format received from server');
        setOrders([]);
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
      setError('Failed to fetch orders');
      setOrders([]);
    } finally {
      setLoading(false);
    }
  }, [apiKey]);
  
  useEffect(() => {
    if (isAuthenticated && apiKey) {
      fetchOrders();
    }
  }, [isAuthenticated, apiKey, fetchOrders]);

  const handleUpdateStatus = async (orderId, newStatus) => {
    setLoading(true);
    try {
      await axios.post(`${API_ENDPOINT}/update_order_status`, {
        orderId: orderId,
        status: newStatus
      }, {
        headers: { 'x-api-key': apiKey }
      });
      await fetchOrders(); // Refresh orders after update
    } catch (error) {
      console.error('Error updating order status:', error);
      setError('Failed to update order status. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const renderOrderCard = (order) => (
    <div key={order.id} className={`order-card status-${order.status}`}>
      <div className="order-header">
        <h3>{order.orderDetails.isBuying ? 'Buy Order' : 'Sell Order'}</h3>
      </div>
      <div className="order-info">
        <p><strong>Order ID:</strong> {order.id}</p>
        <p><strong>Date:</strong> {new Date(order.date).toLocaleString()}</p>
        <p><strong>Contact Method:</strong> {order.customerInfo.contactMethod}</p>
        <p><strong>Contact Info:</strong> {order.customerInfo.contactInfo}</p>
        <p><strong>{order.orderDetails.isBuying ? 'Payment' : 'Payout'}:</strong> {order.paymentInfo.email}</p>
        <p><strong>Payment Method:</strong> {order.paymentInfo.method}</p>
        <p><strong>Amount:</strong> {order.orderDetails.amount.toLocaleString()} coins</p>
        <p><strong>Cost:</strong> ${order.orderDetails.cost}</p>
        <div className="status-buttons">
          <button
            className={`status-button pending ${order.status === 'pending' ? 'active' : ''}`}
            onClick={() => handleUpdateStatus(order.id, 'pending')}
            disabled={loading}
            title="Pending"
          >
            <FontAwesomeIcon icon={faClock} />
          </button>
          <button
            className={`status-button completed ${order.status === 'completed' ? 'active' : ''}`}
            onClick={() => handleUpdateStatus(order.id, 'completed')}
            disabled={loading}
            title="Completed"
          >
            <FontAwesomeIcon icon={faCheck} />
          </button>
          <button
            className={`status-button cancelled ${order.status === 'cancelled' ? 'active' : ''}`}
            onClick={() => handleUpdateStatus(order.id, 'cancelled')}
            disabled={loading}
            title="Cancelled"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </div>
    </div>
  );

  if (!isAuthenticated) {
    return (
      <div className="admin-container">
        <div className="admin-login">
          <h1>Admin Login</h1>
          <form onSubmit={handleLogin}>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              disabled={loading}
            />
            <button type="submit" disabled={loading}>
              {loading ? 'Logging in...' : 'Login'}
            </button>
          </form>
          {error && <p className="error-message">{error}</p>}
        </div>
      </div>
    );
  }

  return (
    <div className="admin-container">
      <div className="admin-panel">
        <div className="admin-header">
          <h1>Admin Panel</h1>
          <button onClick={handleLogout} className="logout-button" disabled={loading}>Logout</button>
        </div>
        {error && <p className="error-message">{error}</p>}
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="order-grid">
            {orders.length > 0 ? (
              orders.map(order => renderOrderCard(order))
            ) : (
              <p>No orders found.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Admin;