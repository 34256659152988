// App.js
import React from 'react';
import { Route, Routes, Link, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faTiktok, faTwitter } from '@fortawesome/free-brands-svg-icons';
import './App.css';
import Coins from './Coins/Coins';
import PrivacyPolicy from './Legal/PrivacyPolicy';
import TermsAndConditions from './Legal/TermsAndConditions';
import Admin from './Admin/Admin';

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleTitleClick = () => {
    navigate('/');
  };

  const renderLegalLinks = () => (
    <div className="legal-links">
      <Link to="/privacy-policy" className={location.pathname === '/privacy-policy' ? 'selected' : ''}>Privacy Policy</Link>
      <Link to="/terms-and-conditions" className={location.pathname === '/terms-and-conditions' ? 'selected' : ''}>Terms and Conditions</Link>    </div>
  );

  return (
    <div className="app-container">
      <header className="app-header">
        <h1 className="app-title" onClick={handleTitleClick}>RPOcoins</h1>
        <div className="header-right">
          <div className="social-icons">
            <a href="https://www.youtube.com/@rpocoins" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <a href="https://www.tiktok.com/@rpocoins" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTiktok} />
            </a>
            <a href="https://x.com/RPOcoins" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </div>
        </div>
      </header>
      <main className="main-content">
        <Routes>
          <Route path="/" element={<Coins />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/admin" element={<Admin />} />
          {/* Catch-all route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </main>
      <footer className="app-footer">
        {renderLegalLinks()}
      </footer>
    </div>
  );
};

export default App;