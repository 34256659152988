// src/Legal/PrivacyPolicy.js
import React from 'react';
import './Legal.css';

const PrivacyPolicy = () => {
  return (
    <div className="legal-container">
      <h1>Privacy Policy</h1>
      <p>Last updated: July 11, 2024</p>
      
      <section>
        <h2>1. Introduction</h2>
        <p>Welcome to RPOcoins. We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, and share your information when you use our website and services.</p>
      </section>
      
      <section>
        <h2>2. Information We Collect</h2>
        <p>We collect personal information that you provide to us, such as your name, email address, and any other information you choose to provide when you:</p>
        <ul>
          <li>Create an account</li>
          <li>Subscribe to our newsletter</li>
          <li>Contact us for support</li>
        </ul>
      </section>
      
      <section>
        <h2>3. How We Use Your Information</h2>
        <p>We use your personal information to:</p>
        <ul>
          <li>Provide and maintain our services</li>
          <li>Communicate with you about our services</li>
          <li>Improve and personalize your experience</li>
        </ul>
      </section>
      
      <section>
        <h2>4. How We Share Your Information</h2>
        <p>We do not sell your personal information. We may share your information with third-party service providers who help us operate our website and provide our services.</p>
      </section>
      
      <section>
        <h2>5. Your Rights</h2>
        <p>You have the right to access, update, or delete your personal information. Please contact us if you wish to exercise these rights.</p>
      </section>
      
      <section>
        <h2>6. Changes to This Policy</h2>
        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
      </section>
      
      <section>
        <h2>7. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at rpocoins@gmail.com.</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;