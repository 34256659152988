// Coins.js
import React, { useState } from 'react';
import './Coins.css';
import Information from './Information';
import Checkout from './Checkout';

const BUY_COST_PER_100K = 5.0;
const SELL_COST_PER_100K = 1.0;

const coinAmounts = [
  100000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000,
  1000000, 1500000, 2000000, 2500000, 3000000, 3500000, 4000000, 4500000, 5000000
];

const Coins = () => {
  const [selectedConsole, setSelectedConsole] = useState('PS5');
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isBuying, setIsBuying] = useState(true);

  const handleConsoleSelect = (console) => {
    setSelectedConsole(console);
  };

  const handleAmountSelect = (amount) => {
    setSelectedAmount(amount);
  };

  const handleTransactionTypeChange = (isBuying) => {
    setIsBuying(isBuying);
  };

  const calculateCost = () => {
    const costPer100K = isBuying ? BUY_COST_PER_100K : SELL_COST_PER_100K;
    return ((selectedAmount / 100000) * costPer100K).toFixed(2);
  };

  const formatAmount = (amount) => {
    return amount >= 1000000 ? `${amount / 1000000}M` : `${amount / 1000}K`;
  };

  const getConsoleColor = () => {
    return selectedConsole === 'PS5' ? 'ps5-blue' : 'xbox-green';
  };

  return (
    <div className="coins-container">
      <div className="coins-form">
        <div className="coins-transaction-type">
          <div className="coins-section-title">Transaction Type</div>
          <div className="coins-transaction-buttons">
            <button 
              className={`coins-transaction-button ${isBuying ? 'selected' : ''}`}
              onClick={() => handleTransactionTypeChange(true)}
            >
              Buy
            </button>
            <button 
              className={`coins-transaction-button ${!isBuying ? 'selected' : ''}`}
              onClick={() => handleTransactionTypeChange(false)}
            >
              Sell
            </button>
          </div>
        </div>
        <div className="coins-console-options">
          <div className="coins-section-title">Console</div>
          <div className="coins-console-buttons">
            <button 
              className={`coins-console-button ps5-blue selected`}
              onClick={() => handleConsoleSelect('PS5')}
            >
              PS5
            </button>
          </div>
        </div>
        <div className="coins-amount-options">
          <div className="coins-section-title">Amount</div>
          <div className="coins-amount-buttons">
            {coinAmounts.map(amount => (
              <button 
                key={amount}
                className={`coins-amount-button ${getConsoleColor()} ${selectedAmount === amount ? 'selected' : ''}`}
                onClick={() => handleAmountSelect(amount)}
              >
                {formatAmount(amount)}
              </button>
            ))}
          </div>
        </div>
        <Checkout 
          isBuying={isBuying}
          selectedConsole={selectedConsole}
          selectedAmount={selectedAmount}
          calculateCost={calculateCost}
        />
      </div>
      <Information isBuying={isBuying} />
    </div>
  );
};

export default Coins;